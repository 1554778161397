import React, { useState, useEffect } from "react";
import "../styled.css";
// service
import { getActivitiesRooms } from "../../../services/scheduling";
// redux
import { useSelector } from "react-redux";
// componentes
import ItemRoomOption from "./ItemRoom";

export default function ListRooms(props) {
  const { setStep, setRoomSelected, type, countTypes, setCountTypes } = props;

  // redux
  const { user, config, saved } = useSelector((state) => state.scheduling);
  const [rooms, setRooms] = useState([]);

  // carregando
  const [loading, setLoading] = useState(true);

  function getListRooms() {
    const columnSearch = type && type !== "todos" ? "Tipo" : "";
    const searchValue = type;
    getActivitiesRooms(user.idEvent, searchValue, columnSearch)
      .then((res) => {
        // console.log("ok", res);
        if (res.total && res.total > 0) {
          var allRooms = [];
          let allTypes = [];

          res.data.map((e) => {
            var search = allRooms.find((room) => room === e.sala);
            if (!search) {
              allRooms.push(e.sala);
            }
          });

          setRooms(allRooms);

          res.data.map((e) => {
            var search = allTypes.find((type) => type.value === e.tipo);

            if (!search && e.tipo !== "") {
              allTypes.push({ value: e.tipo, label: e.tipo });
            }
          });

          props.setOptions(allTypes);

          if (countTypes === 0) {
            props.setCountTypes(allTypes.length);
          }
          getListTypes();
        } else setRooms([]);

        setLoading(false);
      })
      .catch((error) => console.log("erro", error));
  }

  function getListTypes() {
    const columnSearch = "";
    const searchValue = "";
    getActivitiesRooms(user.idEvent, searchValue, columnSearch)
      .then((res) => {
        if (res.total && res.total > 0) {
          let allTypes = [];

          res.data.map((e) => {
            var search = allTypes.find((type) => type.value === e.tipo);

            if (!search && e.tipo !== "") {
              allTypes.push({ value: e.tipo, label: e.tipo });
            }
          });

          props.setOptions(allTypes);

          if (countTypes === 0) {
            props.setCountTypes(allTypes.length);
          }
        } else setRooms([]);

        setLoading(false);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (user) getListRooms();
  }, [user, type]);

  return (
    <div className="div-items-list">
      {loading ? (
        <a>Carregando ...</a>
      ) : (
        rooms.length > 0 &&
        rooms.sort().map((e, i) => (
          <div key={i} style={{ width: "100%" }}>
            <ItemRoomOption
              setStep={setStep}
              name={e}
              setRoomSelected={setRoomSelected}
              config={config}
              saved={saved}
            />
          </div>
        ))
      )}
    </div>
  );
}
