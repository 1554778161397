import writeXlsxFile from "write-excel-file";
import { getByEventAndRoom } from "../../../services/scheduling";
import { formatDate } from "../../../utils/functions";

export async function createXlsx({ eventId, countData, room }) {
  // function formatDateTime(data) {
  //   const date = new Date(data);
  //   const offset = -3 * 60; // Offset in minutes
  //   const localDate = new Date(date.getTime() + offset * 60 * 1000);
  //   const datePart = localDate.toISOString().slice(0, 10);
  //   const timePart = localDate.toISOString().slice(11, 16);

  //   return `${datePart} ${timePart}`;
  // }
  function formatDateTime(data) {
    const localDate = new Date(new Date(data).getTime());
    return localDate
      .toLocaleString("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      })
      .replace(",", " ");
  }
  try {
    const schema = [
      {
        column: "ID do Participante",
        type: String,
        value: (appointment) => appointment.participantId,
      },
      {
        column: "Nome do Participante",
        type: String,
        value: (appointment) => appointment.participantName,
      },
      {
        column: "Sala",
        type: String,
        value: (appointment) => appointment.sala,
      },
      {
        column: "Atividade",
        type: String,
        value: (appointment) => appointment.atividade,
      },
      {
        column: "Data e Hora do Início",
        type: String,
        value: (appointment) => formatDateTime(appointment.dataHoraInicio),
      },
      {
        column: "Data e Hora do Fim",
        type: String,
        value: (appointment) => formatDateTime(appointment.dataHoraFim),
      },
      {
        column: "Tipo",
        type: String,
        value: (appointment) => appointment.tipo,
      },
    ];

    const limit = 50;

    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];

    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getByEventAndRoom({
        eventId,
        room,
        offset: i,
        limit,
        search: "",
        columnSearch: "",
      });

      allRequests.push(response.data);
      setTimeout(() => {}, 500);
    }

    const appointment = allRequests.flat();

    await writeXlsxFile(appointment, {
      schema,
      fileName: "relatorio-agendamentos.xlsx",
    });
  } catch (error) {
    console.log(error);
  }
}
