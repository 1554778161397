import React, { useState, useEffect } from "react";
import { Card, CardTitle, CardBody, Col, Row, Button } from "reactstrap";
import "./styled.css";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
// router
import { useSearchParams } from "react-router-dom";
// moment
import moment from "moment";
// service
import {
  getConfigScheduling,
  getActivitiesByUser,
} from "../../services/scheduling";
// redux
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SchedulingActions from "../../store/actions/scheduling";
// componentes
import ListSaved from "./listSaved";
import ListRooms from "./listRooms";
import ListRoomTimes from "./listRoomTimes";

const HeaderButtons = ({ step, setStep, color, setType }) => {
  const buttons = {
    1: (
      <Button
        onClick={() => setStep(2)}
        className="btn btn-success"
        style={{ ...buttonStyle, backgroundColor: color, borderColor: color }}
      >
        Ver agenda <i className="bx bx-list-ol" style={iconStyle} />
      </Button>
    ),
    2: (
      <Button
        onClick={() => {
          setStep(1);
          setType({});
        }}
        className="btn btn-success"
        style={{ ...buttonStyle, backgroundColor: color, borderColor: color }}
      >
        Ver meus agendamentos{" "}
        <i className="bx bx-check-circle" style={iconStyle} />
      </Button>
    ),
    3: (
      <Button
        onClick={() => setStep(2)}
        className="btn btn-success"
        style={{ ...buttonStyle, backgroundColor: color, borderColor: color }}
      >
        Voltar para agenda <i className="bx bx-list-ol" style={iconStyle} />
      </Button>
    ),
  };

  return <>{buttons[step]}</>;
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    paddingLeft: "30px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    flexDirection: "row-reverse",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: "absolute",
    left: 10,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#aaa",
    paddingLeft: "5px",
  }),
};

const CustomDropdownIndicator = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        left: 10,
        display: "flex",
        alignItems: "center",
      }}
    >
      <FaSearch size={14} color="#aaa" />
    </div>
  );
};

const Search = ({ step, options, type, setType, countTypes }) => (
  <>
    {step === 2 && countTypes > 1 && (
      <Col md={4}>
        <Select
          options={[
            {
              options: [
                { label: "TODOS", value: "todos" },
                ...options.sort((a, b) =>
                  a.label > b.label ? 1 : a.label < b.label ? -1 : 0
                ),
              ],
            },
          ]}
          value={type || { label: "TODOS", value: "todos" }}
          isClearable={true}
          onChange={(e) => setType(e)}
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          styles={customStyles}
          noOptionsMessage={() => "Nenhum tipo encontrado"}
        />
      </Col>
    )}
  </>
);

const buttonStyle = {
  width: "auto",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#1cbe1c",
};

const iconStyle = { fontSize: 19, marginLeft: 5 };

const titles = {
  1: "Meus Agendamentos",
  2: "Agenda",
  3: "Horários da Sala",
};

function AllScheduling(props) {
  const [searchParams] = useSearchParams();
  // redux
  const { config, user } = useSelector((state) => state.scheduling);
  // pagina atual
  const [step, setStep] = useState(0);
  // sala selecionada
  const [roomSelected, setRoomSelected] = useState(null);
  // exibir botão
  const [showBtn, setShowBtn] = useState(true);

  const [options, setOptions] = useState([]);
  const [countTypes, setCountTypes] = useState(0);

  const [type, setType] = useState("");

  function getConfig(id) {
    getConfigScheduling(id)
      .then((res) => {
        // console.log("config", res)
        if (res.id) {
          props.addConfig(res);

          if (
            (res.limitByDate && new Date() < new Date(res.startDate)) ||
            (res.limitByDate && new Date() > new Date(res.endDate))
          ) {
            setShowBtn(false);
          }

          setStep(1);
        } else if (res.message === "NOT_FOUND") {
          setShowBtn(false);
          setStep(4);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  function getListSaved(idEvent, idPart) {
    getActivitiesByUser(idEvent, idPart)
      .then((res) => {
        if (res.length > 0) {
          props.addSaved(res);
        } else props.addSaved([]);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    // resetar o redux
    props.addUser({});
    props.addConfig({});
    props.addSaved([]);

    // pegar parametros
    if (window.location.search) {
      const idEvent = searchParams.get("idEvent");
      const idPart = searchParams.get("idPart");

      props.addUser({
        idEvent,
        idPart,
      });

      getConfig(idEvent);
    }
  }, []);

  useEffect(() => {
    if (step === 1 && user) {
      getListSaved(user.idEvent, user.idPart);
    }
  }, [step, user]);

  return (
    <>
      <section
        className="section-scheduling"
        style={{
          backgroundColor: config.backgroundColor
            ? config.backgroundColor
            : "white",
        }}
      >
        <Card className="card-scheduling">
          <CardBody>
            {showBtn && (
              <Row
                className={
                  step === 2 && countTypes > 1
                    ? "justify-content-between"
                    : "justify-content-end"
                }
              >
                <Search
                  step={step}
                  options={options}
                  type={type}
                  setType={setType}
                  countTypes={countTypes}
                />

                {/* <div style={{ width: step === 2 && countTypes > 1 ? "100%" : "auto", display: "flex", justifyContent: "flex-end" }}> */}
                <div className="div-top-btns" style={{ width: "auto" }}>
                  <HeaderButtons
                    step={step}
                    setStep={setStep}
                    color={config.buttonColor ? config.buttonColor : "#1cbe1c"}
                    setType={setType}
                  />
                </div>
              </Row>
            )}

            <CardTitle className="mt-4">{titles[step]}</CardTitle>

            {step === 1 && <ListSaved getListSaved={getListSaved} />}
            {step === 2 && (
              <ListRooms
                setStep={setStep}
                setRoomSelected={setRoomSelected}
                setOptions={setOptions}
                countTypes={countTypes}
                setCountTypes={setCountTypes}
                type={type?.value}
              />
            )}
            {step === 3 && (
              <ListRoomTimes roomSelected={roomSelected} setStep={setStep} />
            )}
            {step === 4 && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <a>Esse evento não possui configurações</a>
              </div>
            )}
          </CardBody>
        </Card>
      </section>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(SchedulingActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllScheduling);
